import * as React from 'react';
import { Global, css } from '@emotion/core';
import { BREAK_POINTS, colors } from './theme';


const GlobalStyle = (props) => (
  <Global
    {...props}
    styles={css`
      body {
        margin: 0;
      }
      html {
        font-family: sans-serif;
        font-size: 100%;
        -ms-text-size-adjust: 80%;
        -webkit-text-size-adjust: 80%;
        scroll-behavior: smooth;
        background: ${colors.primary.green};
      }
      @media screen and (max-width: ${BREAK_POINTS.xl}px) {
        html {
          font-size: 80%;
        }
      }
      @media screen and (max-width: ${BREAK_POINTS.lg}px) {
        html {
          font-size: 80%;
        }
      }
      @media screen and (max-width: ${BREAK_POINTS.md}px) {
        html {
          font-size: 70%;
        }
      }
      @media screen and (max-width: ${BREAK_POINTS.sm}px) {
        html {
          font-size: 60%;
        }
      }
      ::-moz-selection {
        background: ${colors.select};
        color: ${colors.primary.green};
      }
      ::selection {
        background: ${colors.select};
        color: ${colors.primary.green};
      }
    `}
  />
);

export default GlobalStyle;
