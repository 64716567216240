import * as React from "react";
import styled from "@emotion/styled";
import { ThemeProvider } from "emotion-theming";
import { GlobalStyle, theme } from "~/styles";
import {Helmet} from 'react-helmet' 


const LayoutContainer = styled.div(
  {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  ({ theme }) => ({
    minHeight: `calc(100vh)`,
    backgroundColor: "#01240D",
    ...theme.typography.base,
  })
);

function Layout(props) {
  const { children } = props;

  return (
    <ThemeProvider theme={theme}>
      <Helmet title="XMAS-DinR-20 –– nahschub" description="Der Weihnachtsmann kommt dieses Jahr nicht in rot." />
      <GlobalStyle />
      <LayoutContainer>{children}</LayoutContainer>
    </ThemeProvider>
  );
}

export default Layout;
