export const colors = {
  background: '#FFF7E5',
  primary: {
    green: '#01240D',
    olive: '#828f82',
  },
  black: '#000',
  white: '#fff',
  title: '#EFA796',
  select: '#e28f8f',
  overlay: {
    dark: 'rgba(0,0,0,0.8)',
  },
} ;


const fonts = {
  courier: {
    fontFamily: 'courier-std, monospace',
    fontStyle: 'normal',
  },
  futura: {
    fontFamily: 'futura-pt, sans-serif',
    fontStyle: 'normal',
  },
};


const SPACING = 8;

export const BREAK_POINTS = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

const breakpoints = {
  ...BREAK_POINTS,
  up: (min) =>
    `@media (min-width: ${BREAK_POINTS[min]}px)`,
  down: (max) =>
    `@media (max-width: ${BREAK_POINTS[max]}px)`,
  between: (min, max) =>
    `@media (max-width: ${BREAK_POINTS[max]}px) and (min-width: ${BREAK_POINTS[min]}px)`,
};

const typography = {
  base: {
    ...fonts.futura,
    fontWeight: 400,
    color: colors.primary.olive,
  },
  title: {
    ...fonts.futura,
    fontWeight: 600,
    fontSize: '2.2rem',
    color: colors.primary.green,
  },
  h1: {
    ...fonts.futura,
    fontWeight: 600,
    fontSize: '5rem',
    lineHeight: '5.5rem',
    WebkitTextStrokeWidth: 1,
    WebkitTextStrokeColor: colors.primary.green,
    color: colors.background,
    wordWrap: 'break-word',
    hyphens: 'auto',
    [breakpoints.down('sm')]: {
      fontSize: '3.5rem',
      lineHeight: '3.7rem',
    },
  },
  h3: {
    ...fonts.futura,
    fontSize: '1.5rem',
    color: colors.primary.green,
    fontWeight: 600,
    wordWrap: 'break-word',
    hyphens: 'auto',
  },
  subtitle: {
    ...fonts.futura,
    fontSize: '1.1rem',
    color: colors.primary.green,
  },
  outlined: {
    WebkitTextStrokeWidth: 1,
    WebkitTextStrokeColor: colors.primary.green,
    color: colors.background,
  },
};


function spacing(...args) {
  if (args.length > 0 && args.length <= 4) {
    return args.map((arg) => `${SPACING * arg}px`).join(' ');
  }
  console.warn('Between one and four arguments must be provided for spacing');
  return '';
}

const sizes = {
  header: SPACING * 8,
  bottomBar: SPACING * 8,
};

const theme = {
  colors,
  typography,
  breakpoints,
  spacing,
  sizes,
  fonts,
};

export default theme;
